@import "src/scss/styles";
.access {
  display: flex;
  margin: 0 auto;
  background-color: $black;
  position: relative;

  min-height: calc(50vh - 84px);
  padding: 82px 74px;

  @media screen and #{$xl} {
    min-height: calc(50vh - 97px);
  }

  @include tablet {
    display: block;

    min-height: calc(50vh - 113px);
    padding: 82px 24px;
  }

  @include phone {
    min-height: calc(50vh - 73px);
    padding: 64px 24px;
  }
}

.access__inner {
  display: grid;
  gap: 2rem;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    gap: 3rem;
    margin-bottom: 8rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.access__title {
  position: relative;
  z-index: 2;

  font-weight: 500;
  font-size: 52px;
  line-height: 1.2;

  @include tablet {
    font-size: 42px;
  }

  @include phone {
    font-size: 24px;
  }
}

.access__description {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 790px;

  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;

  @include tablet {
    font-size: 16px;
  }

  @include phone {
    font-size: 14px;
  }
}

.access__image {
  position: absolute;
  bottom: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  inset: 0;
}
