@import "src/scss/styles";
.why {
  overflow: hidden;
  position: relative;
  z-index: 0;
  background-color: $black;

  @include tablet {
    min-height: calc(100vh - 221px);
  }

  @include phone {
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    inset: 0;
  }
}

.why__bg {
  position: absolute;
  z-index: -1;
  inset: 0;
  overflow: hidden;
  // background: #421775;
  background: #111111;

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: -800px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    // width: 1566px;
    height: 1566px;
    background: linear-gradient(
      148.7deg,
      #421775 -16.31%,
      #300f58 55.84%,
      #22014a 122.38%
    );
    filter: blur(200px);
  }
}

.why__inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: calc(65vh - 84px);
  padding: 82px 67px;

  @include tablet {
    padding: 62px 24px;
  }

  @include phone {
    padding: 52px 24px;
  }
}

.why__title {
  max-width: 1220px;
  margin-bottom: 48px;

  @include tablet {
    margin-bottom: 42px;
  }

  @include phone {
    margin-bottom: 24px;
  }
}

.why__image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  img {
    @include cover-img;

    @media screen and #{$xl} {
      object-position: 0 0;
    }

    @include tablet {
      object-position: center;
    }
  }
}

.why__list {
  @include list-reset;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.why__list-item {
  display: flex;
  &:nth-of-type(even) {
    border-left: 1px solid $white;

    @include tablet {
      border-left: 0;
      border-top: 1px solid $white;
    }
  }
}

.why-card {
  padding: 52px 32px;
  background-color: #1f1c21;

  @include tablet {
    padding: 32px 24px;
  }

  @include phone {
    padding: 24px 16px;
  }
}

.why-card__title {
  margin-bottom: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  @include tablet {
    margin-bottom: 24px;
  }

  @include phone {
    font-size: 18px;
    line-height: 24px;
  }
}

.why-card__text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  @include tablet {
    font-size: 14px;
    line-height: 22px;
  }

  @include phone {
    font-size: 12px;
    line-height: 20px;
  }

  b {
    font-weight: 500;
  }
}
