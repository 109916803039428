@import "src/scss/styles";
// @import "./_colors.scss";

.get-started-steps {
  color: $white;
  width: 100%;
  max-width: 1400px;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 5rem 2.5rem;
  }

  @media (min-width: 1280px) {
    padding: 8rem 5rem;
  }

  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .desktop-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;

    .steps-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .step-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        color: $light-grey;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;

        .step-number {
          border: 1px solid $light-grey;
          width: 3.125rem;
          height: 3.125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;

          @include tablet {
            width: 2.125rem;
            height: 2.125rem;
          }
        }

        &.active {
          color: $white;
          font-size: 1.2rem;
          font-weight: 600;

          .step-number {
            background-color: $violet;
            border-color: $violet;
          }
        }
      }
    }

    .step-details {
      flex: 1;
      margin: auto;

      .step-detail-content {
        .icon {
          height: 4rem;
          width: 4rem;
          margin-bottom: 1rem;
        }

        .icon svg {
          color: $violet;
          fill: $violet;
        }

        h3 {
          font-size: 2rem;
          margin-bottom: 1rem;
          line-height: 100%;

          @include tablet {
            font-size: 1.6rem;
          }
        }

        div,
        ul {
          font-size: 1.2rem;
          color: $light-grey;

          p {
            margin: 0.5rem 0;
            @include tablet {
              font-size: 1.1rem;
            }
          }

          strong {
            color: $white;
          }

          li {
            list-style: disc;
          }
        }
      }
    }
  }

  .mobile-view {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: block;

      .accordion-item {
        border-bottom: 1px solid $light-grey;

        .accordion-header {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 1rem 0;
          cursor: pointer;
          color: $light-grey;

          .step-number {
            border: 1px solid $light-grey;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
          }
        }

        .accordion-content {
          overflow: hidden;
          .icon {
            height: 3rem;
            width: 3rem;
            margin-bottom: 0.5rem;
          }

          .icon svg {
            color: $violet;
            fill: $violet;
          }

          div,
          ul {
            font-size: 1rem;
            color: $light-grey;

            p {
              margin: 0.5rem 0;
            }

            strong {
              color: $white;
            }

            li {
              margin-left: 1rem;
              list-style: disc;
            }
          }
        }
      }
    }
  }
}
