$white: #ffffff;
$black: #000000;

$dark: #212121;
$violet: #9c21e8;
$violet-l: #dbbfff;
$violet-2: #ddc0ff;

$white-op-3: #ffffff4d;

$grey: #4e616b;
$light-grey: #989898;

$error: #c10101;
